import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { Heading, Text } from 'theme-ui'

const styles = {
  image: {
    verticalAlign: `middle`
  },
  grayscale: {
    WebkitFilter: `grayscale(1)`,
    filter: `grayscale(1)`,
    opacity: `0.7`
  },
  title: {
    m: 0,
    display: `flex`,
    alignItems: `flex-end`,
    color: `#555`
  },
  large: {
    fontSize: 32
  }
}

const Logo = ({ title, grayscale, image, to, ...props }) => (
  <Heading
    as={Link}
    to={to}
    alt={title}
    aria-label={title}
    variant='h2'
    sx={styles.title}
    {...props}
  >
    <Text sx={styles.large}>Forestone</Text>
  </Heading>
)

export default Logo

Logo.defaultProps = {
  to: '/'
}

Logo.propTypes = {
  title: PropTypes.string,
  grayscale: PropTypes.bool,
  fixed: PropTypes.object, //gatsby-transform-sharp
  to: PropTypes.string
}
